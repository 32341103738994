import styled from '@emotion/styled'
import { Toolbars } from 'app/components/Common/Toolbars'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  address?: string
  bookingOffice?: string
  email?: string
  facebookURL?: string
  fax?: string
  IBEID?: number
  instagramURL?: string
  languageCode: string
  languagePrefix?: string | null
  linkFooterLabel?: string
  linkFooterURL?: string
  logo?: string
  phone?: string
  siteName?: string
  vat?: string
  whatsapp?: string
}

export const Footer = memo(function Footer({
  address,
  bookingOffice,
  email,
  facebookURL,
  fax,
  IBEID,
  instagramURL,
  languageCode,
  languagePrefix,
  linkFooterLabel,
  linkFooterURL,
  logo,
  phone,
  siteName,
  vat,
  whatsapp,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {logo ? (
          <Logo src={logo} alt={siteName} width="123" height="98" />
        ) : null}
        <Head row space="between" wrap>
          <Cell>
            <Label>{siteName}</Label>
            {address ? <Address>{address}</Address> : null}
          </Cell>
          <Cell>
            <Label>{useVocabularyData('contacts', languageCode)}</Label>
            {phone ? (
              <Contact>
                <Anchor
                  href={`tel:${phone}`}
                  onClick={() => {
                    if (isMobile) {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'Click', {
                          event_category: 'Website',
                          event_label: 'Mobile Phone',
                        })
                    }
                  }}
                >
                  {`${useVocabularyData('ph', languageCode)}: ${phone}`}
                </Anchor>
              </Contact>
            ) : null}
            {bookingOffice ? (
              <Contact>
                <Anchor
                  href={`tel:${bookingOffice}`}
                  onClick={() => {
                    if (isMobile) {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'Click', {
                          event_category: 'Website',
                          event_label: 'Mobile Phone',
                        })
                    }
                  }}
                >
                  {`${useVocabularyData(
                    'booking-office',
                    languageCode,
                  )}: ${bookingOffice}`}
                </Anchor>
              </Contact>
            ) : null}
            {whatsapp ? (
              <Contact>
                <Anchor
                  href={`https://api.whatsapp.com/send?phone=${whatsapp}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {`${useVocabularyData(
                    'whatsapp',
                    languageCode,
                  )}: ${whatsapp}`}
                </Anchor>
              </Contact>
            ) : null}
            {fax ? (
              <Contact>
                <Fax>{`${useVocabularyData('fax', languageCode)}: ${fax}`}</Fax>
              </Contact>
            ) : null}
            {email ? (
              <Contact>
                <Email
                  href={`mailto:${email}`}
                  onClick={() => {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'Click', {
                        event_category: 'Website',
                        event_label: 'Email Address',
                      })
                  }}
                >
                  {`${useVocabularyData('email', languageCode)}: ${email}`}
                </Email>
              </Contact>
            ) : null}
          </Cell>
          <Cell>
            <Label>{useVocabularyData('social', languageCode)}</Label>
            <SocialWrap row>
              {facebookURL ? (
                <SocialItem
                  aria-label="Facebook"
                  href={facebookURL}
                  rel="noreferrer"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="20"
                    viewBox="0 0 10 20"
                  >
                    <path
                      d="M134.25,6.875v-2.5a1.25,1.25,0,0,1,1.25-1.25h1.25V0h-2.5a3.75,3.75,0,0,0-3.75,3.75V6.875H128V10h2.5V20h3.75V10h2.5L138,6.875Z"
                      transform="translate(-128)"
                    />
                  </svg>
                </SocialItem>
              ) : null}
              {instagramURL ? (
                <SocialItem
                  aria-label="Instagram"
                  href={instagramURL}
                  rel="noreferrer"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.997"
                    height="20"
                    viewBox="0 0 19.997 20"
                  >
                    <path d="M5.876,19.941a7.362,7.362,0,0,1-2.425-.465A5.131,5.131,0,0,1,.524,16.55a7.343,7.343,0,0,1-.465-2.426C.013,13.057,0,12.717,0,10S.013,6.946.063,5.876A7.337,7.337,0,0,1,.527,3.45,4.938,4.938,0,0,1,1.681,1.68,4.871,4.871,0,0,1,3.454.522,7.314,7.314,0,0,1,5.882.059C6.947.011,7.288,0,10,0s3.055.011,4.125.062a7.319,7.319,0,0,1,2.428.465,5.117,5.117,0,0,1,2.926,2.927,7.326,7.326,0,0,1,.465,2.425C19.994,6.946,20,7.285,20,10s-.011,3.055-.059,4.122a7.312,7.312,0,0,1-.465,2.426,5.114,5.114,0,0,1-2.927,2.926,7.323,7.323,0,0,1-2.425.465C13.054,19.987,12.715,20,10,20S6.944,19.987,5.876,19.941ZM5.971,1.875a5.488,5.488,0,0,0-1.856.344,3.106,3.106,0,0,0-1.149.746,3.022,3.022,0,0,0-.751,1.149A5.49,5.49,0,0,0,1.872,5.97c-.047,1.051-.059,1.371-.059,4.04s.011,2.985.059,4.04a5.515,5.515,0,0,0,.343,1.855,3.126,3.126,0,0,0,.746,1.148,3.092,3.092,0,0,0,1.15.746,5.491,5.491,0,0,0,1.856.343c1.051.047,1.371.059,4.04.059s2.985-.011,4.04-.059A5.507,5.507,0,0,0,15.9,17.8a3.321,3.321,0,0,0,1.9-1.9,5.472,5.472,0,0,0,.343-1.855c.047-1.055.059-1.368.059-4.036s-.011-2.985-.059-4.04A5.5,5.5,0,0,0,17.8,4.114a3.1,3.1,0,0,0-.748-1.149,3.037,3.037,0,0,0-1.148-.746,5.468,5.468,0,0,0-1.856-.344c-1.051-.047-1.367-.059-4.04-.059S7.025,1.828,5.971,1.875ZM4.866,10A5.137,5.137,0,1,1,10,15.14,5.138,5.138,0,0,1,4.866,10Zm1.8,0A3.333,3.333,0,1,0,10,6.669,3.333,3.333,0,0,0,6.67,10Zm7.474-5.34a1.2,1.2,0,1,1,1.2,1.2A1.2,1.2,0,0,1,14.144,4.661Z" />
                  </svg>
                </SocialItem>
              ) : null}
              {whatsapp ? (
                <SocialItem
                  aria-label="Whatsapp"
                  href={`https://api.whatsapp.com/send?phone=${whatsapp}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M20331-1741a9.9,9.9,0,0,1-5.484-1.652l-3.844,1.225,1.25-3.713A9.884,9.884,0,0,1,20321-1751a10.018,10.018,0,0,1,10-10,10.008,10.008,0,0,1,10,10A10,10,0,0,1,20331-1741Zm-3.766-15.426a1.393,1.393,0,0,0-1.016.357l-.016.027a3.19,3.19,0,0,0-1,2.4,5.6,5.6,0,0,0,1.172,3.01l.016.008c0,.014.031.039.047.076a12.068,12.068,0,0,0,4.922,4.32,8.542,8.542,0,0,0,2.922.822,2.461,2.461,0,0,0,.594-.07,2.792,2.792,0,0,0,1.953-1.41,2.33,2.33,0,0,0,.172-1.385,1,1,0,0,0-.391-.264c-.016-.01-.062-.021-.078-.033a.52.52,0,0,0-.094-.047c-.234-.119-1.7-.844-2-.943a.676.676,0,0,0-.25-.053.55.55,0,0,0-.453.27l-.125.172a9.025,9.025,0,0,1-.656.861.551.551,0,0,1-.422.17.681.681,0,0,1-.281-.055.292.292,0,0,0-.094-.035l-.016-.014a7.248,7.248,0,0,1-2.234-1.4,8.254,8.254,0,0,1-1.609-2.016.436.436,0,0,1,.094-.615l.016-.01a2.4,2.4,0,0,1,.25-.295l.016-.01a1.992,1.992,0,0,0,.156-.174l.047-.041v-.018a1.3,1.3,0,0,0,.266-.4.559.559,0,0,0-.031-.543c-.062-.111-.406-.963-.609-1.459-.078-.191-.156-.373-.2-.525l-.094-.178c-.187-.461-.328-.484-.625-.5l-.156,0h0C20327.375-1756.423,20327.313-1756.425,20327.234-1756.425Z"
                      transform="translate(-20321 1760.999)"
                    />
                  </svg>
                </SocialItem>
              ) : null}
            </SocialWrap>
          </Cell>
        </Head>
        <Bottom dial={7} row space="between" wrap>
          <Cell>
            {siteName ? <SiteName>{siteName} - </SiteName> : null}
            {vat ? (
              <Vat>{`${useVocabularyData('vat', languageCode)} ${vat}`}</Vat>
            ) : null}
            <Policy row wrap>
              <Link
                to={`${
                  languagePrefix ? `/${languagePrefix}` : ''
                }/privacy-policy`}
              >
                {useVocabularyData('privacy-policy', languageCode)}
              </Link>
              <Cookie
                onClick={() =>
                  // @ts-ignore
                  Cookiebot.show()
                }
              >
                {useVocabularyData('cookie-policy', languageCode)}
              </Cookie>
              <Link target="_blank" to={`${linkFooterURL}`}>
                {linkFooterLabel}
              </Link>
            </Policy>
          </Cell>
          <Credits href="https://www.qnt.it" target="_blank" rel="noopener">
            webdesign <b>QNT Hospitality</b>
          </Credits>
        </Bottom>
      </Wrapper>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media lessThan="ipadVertical">
          <Toolbars
            languageCode={languageCode}
            languagePrefix={languagePrefix || null}
            email={email}
            phone={phone}
            whatsapp={whatsapp}
          />
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding-bottom: 5.625rem;

  @media (max-width: 767px) {
    padding-bottom: 3.75rem;
  }
`

const Wrapper = styled.div`
  max-width: 67.25rem;
  margin: auto;
  padding: 13.3vh 1.5rem 0;

  @media (max-width: 1023px) {
    padding-top: 6rem;
  }

  @media (max-width: 767px) {
    padding-top: 3.75rem;
  }
`

const Logo = styled.img`
  display: block;
  margin: 0 auto 4.25rem;
`

const Head = styled(FlexBox)``

const Cell = styled.div`
  max-width: 19.444vw;

  @media (max-width: 1023px) {
    max-width: none;
  }

  @media (max-width: 767px) {
    width: 100%;
    text-align: center;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1.125rem;
  margin-bottom: 0.5625rem;
  text-transform: uppercase;

  @media (max-width: 767px) {
    margin-top: 3rem;
  }
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
`

const Contact = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
`

const Anchor = styled.a`
  &:hover {
    color: ${({ theme }) => theme.colors.variants.neutralDark1};
  }
`

const Fax = styled.div``

const Email = styled.a`
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.variants.neutralDark1};
  }
`

const SocialWrap = styled(FlexBox)`
  @media (max-width: 767px) {
    justify-content: center;
  }
`

const SocialItem = styled.a`
  margin-right: 1.5rem;
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.variants.primaryDark1};
    }
  }
  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralDark4};
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 767px) {
    margin: 0 0.75rem;
  }
`

const Bottom = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1.25rem;
  margin-top: 13.3vh;
  > div {
    max-width: none;
  }

  @media (max-width: 1023px) {
    margin-top: 6rem;
  }

  @media (max-width: 767px) {
    margin-top: 3.375rem;
  }
`

const SiteName = styled.span``

const Vat = styled.span``

const Policy = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-top: 0.6875rem;
  a {
    margin-right: 1.125rem;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
  }
`

const Cookie = styled.a`
  cursor: pointer;
`

const Credits = styled.a`
  b {
    font-weight: 600;
  }

  @media (max-width: 767px) {
    margin: 0.75rem auto 0;
  }
`
